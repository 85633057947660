<template>
  <v-dialog
    :value="showHelp"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card max-width="1000" class="mx-auto pb-10" flat>
        <v-card-title class="flex justify-space-between">
          <h4 class="text-h4 py-5">Manual de Usuario</h4>
          <v-btn color="primary" @click="$emit('closeHelp')">Cerrar</v-btn>
        </v-card-title>

        <v-card-text>
          <v-expansion-panels :class="{ 'px-5': $vuetify.breakpoint.smAndUp }">
            <EasyServeAuthorizationManual></EasyServeAuthorizationManual>
            <EasyServeOrdersManual></EasyServeOrdersManual>
            <EasyServeHistoryManual></EasyServeHistoryManual>
            <EasyServeCategoriesManual></EasyServeCategoriesManual>
            <EasyServeProductsManual></EasyServeProductsManual>
            <EasyServeDailyMenuManual></EasyServeDailyMenuManual>
            <EasyServeAllergensManual></EasyServeAllergensManual>
            <EasyServeContactManual></EasyServeContactManual>
            <EasyServeEmployeesManual></EasyServeEmployeesManual>
            <EasyServeConfigurationManual></EasyServeConfigurationManual>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import EasyServeAuthorizationManual from './EasyServeAuthorizationManual';
import EasyServeOrdersManual from './EasyServeOrdersManual';
import EasyServeHistoryManual from './EasyServeHistoryManual';
import EasyServeCategoriesManual from './EasyServeCategoriesManual';
import EasyServeProductsManual from './EasyServeProductsManual';
import EasyServeDailyMenuManual from './EasyServeDailyMenuManual';
import EasyServeAllergensManual from './EasyServeAllergensManual';
import EasyServeContactManual from './EasyServeContactManual';
import EasyServeEmployeesManual from './EasyServeEmployeesManual';
import EasyServeConfigurationManual from './EasyServeConfigurationManual';

export default {
  props: {
    showHelp: Boolean,
  },

  components: {
    EasyServeAuthorizationManual,
    EasyServeOrdersManual,
    EasyServeHistoryManual,
    EasyServeCategoriesManual,
    EasyServeProductsManual,
    EasyServeDailyMenuManual,
    EasyServeAllergensManual,
    EasyServeContactManual,
    EasyServeEmployeesManual,
    EasyServeConfigurationManual,
  },
};
</script>

<style>
ul {
  list-style: square inside
    url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
}
ol > li {
  padding-left: 9px;
}
</style>
