var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(!_vm.isConnected)?_c('v-system-bar',{attrs:{"app":"","height":"38","color":"error darken-1"}},[_c('v-icon',{attrs:{"color":"error lighten-5","dark":""}},[_vm._v(" mdi-lan-disconnect ")]),_c('span',{staticClass:"red--text text--lighten-5 ml-2"},[_vm._v(" Se ha producido un error con la conexión al sistema de pedidos. ")])],1):_vm._e(),_c('v-navigation-drawer',{attrs:{"app":"","dark":"","color":"blue darken-1"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-git")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.version)+" ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-toolbar',{staticClass:"mt-2",attrs:{"color":"blue darken-1","flat":""}},[_c('v-select',{staticClass:"mt-3",attrs:{"items":_vm.restaurants,"item-text":"name","item-value":"uuid","label":"Restaurantes Registrados","prepend-icon":"mdi-store","value":_vm.selected},on:{"change":_vm.changed}})],1),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
          name: 'clients',
          params: { restaurant: _vm.currentSelectedRestaurant },
        },"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-question")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.clients')))])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'orders',
          params: { restaurant: _vm.currentSelectedRestaurant },
        },"exact":"","link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shopping")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.orders')))])],1),(_vm.orders.length > 0)?_c('v-list-item-action',[_c('v-chip',{staticClass:"elevation-2",attrs:{"color":"green","dark":"","small":""}},[_c('v-icon',{attrs:{"small":"","left":"","color":"white"}},[_vm._v("mdi-bell")]),_vm._v(" "+_vm._s(_vm.orders.length)+" ")],1)],1):_vm._e()],1),_c('v-list-item',{attrs:{"to":{
          name: 'history',
          params: { restaurant: _vm.currentSelectedRestaurant },
        },"exact":"","link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-history")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.history')))])],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-list-item',{attrs:{"to":{
          name: 'categories',
          params: { restaurant: _vm.currentSelectedRestaurant },
        },"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shape")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.categories')))])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'daily-menus',
          params: { restaurant: _vm.currentSelectedRestaurant },
        },"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-book-open-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.dailyMenus')))])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'allergens',
          params: { restaurant: _vm.currentSelectedRestaurant },
        },"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-peanut-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.allergens')))])],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-list-group',{attrs:{"id":"nav-items-restaurant","nav":"","prepend-icon":"mdi-store","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Restaurante")])]},proxy:true}])},[_c('v-list-item',{attrs:{"to":{
            name: 'contact-information',
            params: { restaurant: _vm.currentSelectedRestaurant },
          },"exact":"","link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.contactInformation')))]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-badge-account-horizontal")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'employees',
            params: { restaurant: _vm.currentSelectedRestaurant },
          },"exact":"","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.employees')))])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-supervisor")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'configuration',
            params: { restaurant: _vm.currentSelectedRestaurant },
          },"exact":"","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.configuration')))])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'tables',
            params: { restaurant: _vm.currentSelectedRestaurant },
          },"exact":"","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('navigation.tables')))])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-table-chair")])],1)],1)],1),_c('v-divider',{staticClass:"my-3"}),(_vm.isAdmin || _vm.isEmployee || _vm.isOwner)?_c('v-list-group',{attrs:{"id":"nav-items-restaurant","nav":"","prepend-icon":"mdi-storefront","no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Cobros / Facturas")])]},proxy:true}],null,false,939865005)},[_c('v-list-item',{attrs:{"to":{
            name: 'payment-method',
            params: { restaurant: _vm.currentSelectedRestaurant },
          },"exact":"","link":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('navigation.paymentMethod'))+" ")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-credit-card-multiple")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'subscriptions',
            params: { restaurant: _vm.currentSelectedRestaurant },
          },"exact":"","link":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('navigation.subscriptions'))+" ")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cube-send")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'invoices',
            params: { restaurant: _vm.currentSelectedRestaurant },
          },"exact":"","link":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('navigation.invoices'))+" ")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-download-outline")])],1)],1)],1):_vm._e(),(_vm.isAdmin || _vm.isEmployee)?_c('v-divider',{staticClass:"my-3"}):_vm._e(),(_vm.isAdmin || _vm.isEmployee)?_c('v-list-group',{attrs:{"id":"nav-items-administration","prepend-icon":"mdi-google-my-business","value":false,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Administracion")])]},proxy:true}],null,false,3923296894)},[_c('v-list-item',{attrs:{"to":{ name: 'administration.restaurants' },"exact":"","link":""}},[_c('v-list-item-title',[_vm._v("Restaurantes")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-store")])],1)],1),_c('v-list-item',{attrs:{"to":{ name: 'administration.packs' },"exact":"","link":""}},[_c('v-list-item-title',[_vm._v("Packs Mesas")]),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-package-variant")])],1)],1),_c('v-list-item',{attrs:{"to":{
            name: 'employees',
            params: { restaurant: _vm.currentSelectedRestaurant },
          },"exact":"","link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Idiomas")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-translate")])],1)],1)],1):_vm._e()],1)],1),_c('v-app-bar',{attrs:{"app":"","color":"white","flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_vm._v(" EasyServe Administración ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){_vm.showHelp = true}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-help-circle-outline")])],1),_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","large":""}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-circle")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.username))]),_c('v-list-item-subtitle',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.email)+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',{staticClass:"pl-2"},[_vm._v("Logout")])],1)],1)],1)],1),_c('router-view'),_c('v-footer',{attrs:{"dark":"","padless":"","app":"","inset":"","absolute":""}},[_c('v-card',{staticClass:"grey darken-3 white--text text-center",attrs:{"width":"100%","flat":"","tile":""}},[_c('v-card-text',{staticClass:"white--text"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" — "),_c('strong',[_vm._v("EasyServe")])])],1)],1),_c('EasyServeManual',{attrs:{"showHelp":_vm.showHelp},on:{"closeHelp":function($event){_vm.showHelp = false}}}),_c('eximyr-snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }