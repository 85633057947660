<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Menús del día
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Menús del día</h3>

      <p>
        Los menús del día son una parte esencial de nuestro restaurante. A
        través de la opción “Menús del día” podemos acceder a la sección.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_01.png"
        max-width="400"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si no disponemos de ningún menú del día, el sistema nos mostrará el
        mensaje
      </p>

      <p class="font-italic">
        “No hay ningún menú del día actualmente en el sistema. Por favor,
        utilice el botón "añadir" que se encuentra abajo a la derecha para crear
        uno.”
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_02.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Vamos a crear el primer menú. Hacemos clic en el botón “Añadir”, se
        encuentra en la parte derecha abajo de la página, rojo con el símbolo
        “+” en el centro.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_03.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        En la página “Crear nuevo menú del día” se nos presenta el formulario
        para introducir los datos generales del menú. En el primer campo podemos
        seleccionar una imagen del menú, después tenemos el título, la
        descripción y notas al final del menú en los idiomas configurados.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_04.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si hemos configurado más de un idioma para nuestro restaurante entonces
        tendremos que añadir el detalle del menú también en los otros idiomas.
      </p>

      <p>
        Después de los detalles generales tenemos que indicar el precio del menú
        por defecto (esto es para un menú con bebida, primer plato, segundo
        plato, postre y/o café).
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_05.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p>
        Como podemos observar en este momento no se permite activar el menú del
        día ya que no tiene ningún producto activo en cada sección. Necesitamos
        tener al menos una bebida, un primer plato, un segundo plato, un postre
        y un café activos para poder activar el menú. Una vez guardado el menú
        del día se nos muestra la tarjeta del mismo.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_06.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Haciendo clic sobre el boton azul (boton de productos) nos muestra la
        parte de configuración y de productos del menú.
      </p>

      <p>
        En esta página podemos configurar al detalle nuestro menú. La página
        está separada en 5 apartados:
      </p>

      <ol>
        <li>Configuración (configuración del menú)</li>
        <li>Bebidas (las bebidas que el cliente puede seleccionar)</li>
        <li>
          Primer plato (los primeros platos que el cliente puede seleccionar)
        </li>
        <li>
          Segundo plato (los segundos platos que el cliente puede seleccionar)
        </li>
        <li>Postres (los postres disponibles para los clientes)</li>
        <li>
          Cafés o infusiones (los cafés e infusiones disponibles para los
          clientes)
        </li>
      </ol>

      <v-img
        src="./images/daily-menus/es.daily-menus_07.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        <strong>Empezamos con la parte de configuración. </strong>
      </p>

      <p>
        En la primera opción indicamos si el menú incluye tanto postre como café
        (si está activado el sistema permitirá a los clientes seleccionar tanto
        el postre como el café, si está desactivada la opción, los clientes solo
        podrán elegir un postre o un café).
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_08.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Opción de <strong>un solo primer plato</strong>, hay gente que solo
        quiere pedir un primer plato, con esta opción permitimos a los clientes
        seleccionar un primer plato y además podemos indicar el precio del menú
        en caso de un solo plato. Si queremos cobrar el mismo precio entonces
        indicaremos el mismo precio en el campo “Precio menú solo primer plato”.
        El precio se debe indicar en céntimos, si el precio es 7,50€ entonces
        escribiremos “750” en el campo de texto.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_09.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Opción de <strong>dos primeros platos</strong>, habrá clientes que les
        gusten más dos platos principales. Para indicar al sistema que se pueden
        pedir dos primeros activamos el checkbox de “Se pueden pedir dos
        primeros” e indicamos el precio para esta combinación en el campo de
        texto de debajo. El precio se debe indicar en céntimos, si el precio es
        9,00€ entonces escribiremos “900” en el campo de texto.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_10.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Opción de <strong>un solo segundo plato</strong>, otra posibilidad para
        nuestro menú es que los clientes puedan pedir un segundo plato
        únicamente. Para esto activamos la opción “Se puede pedir solo un
        segundo plato” e indicamos el importe de la configuración en el campo de
        texto de debajo. El precio se debe indicar en céntimos, si el precio es
        8,50€ entonces escribiremos “850” en el campo de texto.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_11.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Por último, podemos permitir a nuestros clientes que seleccionen
        <strong> dos segundos </strong> activando el checkbox “se pueden pedir
        dos segundos” e informando el importe a cobrar en el campo de texto de
        debajo de la opción. El precio se debe indicar en céntimos, si el precio
        es 12,50€ entonces escribiremos “1250” en el campo de texto.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_12.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Con esto hemos terminado la parte de configuración de platos y cafés /
        postres de nuestro menú del día.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_13.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Hacemos clic en el botón continuar para guardar los cambios y empezar
        con la sección de bebidas. Si no tenemos bebidas dadas de alta para este
        menú, la aplicación nos muestra el mensaje “Sin bebidas”.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_14.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Hacemos click sobre el botón “Añadir” para crear una nueva bebida en
        este menú.
      </p>

      <p>
        Escribimos el nombre y la descripción del producto, en este caso vamos a
        empezar por Vino y Casera en los idiomas configurados (ver
        <strong>configuración</strong>
        para cambiar los idiomas).
      </p>

      <p>
        A continuación seleccionamos los alérgenos del producto, para algunos
        vinos tenemos lácteos y derivados, huevos y derivados y sulfitos.
      </p>

      <p>
        El siguiente campo es para indicar si al seleccionar este producto se
        debe añadir algo al precio del menú. Imaginemos que el menú normal vale
        15€ pero si el cliente quiere un chuletón de buey de 500g entonces se le
        debe añadir otros 10€ al menú. Si es el caso en este campo se debe
        indicar el importe a utilizar. El importe se debe informar en céntimos,
        por ejemplo para 10,00€ escribiremos “1000” en el campo de texto.
      </p>

      <p>
        Por último tenemos la opción de activar / desactivar el producto para
        que los clientes no lo puedan seleccionar y los botones cancelar y
        guardar. Al hacer clic sobre el botón <strong>Guardar</strong> se
        guardará el producto en el sistema y regresaremos a la página anterior.
      </p>

      <p>Si cancelamos vamos a perder todos los datos introducidos.</p>

      <v-img
        src="./images/daily-menus/es.daily-menus_15.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Ahora vemos que en la sección de bebidas tenemos nuestra primera bebida
        creada. Seguimos el mismo proceso para crear todas las bebidas ofrecidas
        con este menú.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_16.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si queremos modificar un producto de un menú del día hacemos clic sobre
        los 3 puntos verticales de la derecha del producto y elegimos la opción
        “Modificar”.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_17.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        La aplicación nos muestra de nuevo el formulario de alta pero esta vez
        con los datos del producto seleccionado. Realizamos las modificaciones
        pertinentes y con el botón “Guardar” salvamos los cambios. Si pinchamos
        en el botón cancelar ningún cambio será realizado.
      </p>

      <p>
        En el mismo desplegable tenemos la opción de eliminar un producto si así
        lo deseamos. Al hacer clic sobre la opción Eliminar el sistema nos
        muestra un mensaje de confirmación antes de realizar el borrado. El
        botón cancelar, cancela la operación y el “Eliminar” realiza el borrado.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_18.png"
        max-width="400"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Desde la sección de bebidas, una vez hayamos acabado con todas las
        bebidas podemos hacer clic en el botón continuar para seguir con los
        primeros platos.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_19.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si no tenemos productos en la sección de primeros platos el sistema nos
        muestra el mensaje “Sin primeros platos”. Hacemos clic, igual que para
        las bebidas, en el botón “Añadir” para crear un nuevo primer plato. Los
        campos son iguales que en el caso de bebidas, creamos nuestro producto y
        lo guardamos con el botón guardar.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_20.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Al igual que en el caso de las bebidas, una vez hemos dado de alta todos
        los primeros platos hacemos clic en continuar para seguir con la
        siguiente sección, segundos platos.
      </p>

      <p>
        Si queremos saltarnos una sección, por ejemplo estamos editando los
        primeros platos y queremos modificar la configuración, podemos hacer
        click sobre el texto “Configuración” y se nos abre dicha sección para
        poder modificarla. El proceso no se debe realizar de forma secuencial.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_21.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        El proceso de alta de productos es idéntico a las otras categorías,
        creamos nuestros segundos platos utilizando el botón “Añadir”. Una vez
        creados nuestros segundos platos hacemos clic en continuar para pasar a
        la sección de postres.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_22.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Como vemos todas las secciones son iguales, damos de alta los productos
        con el botón Añadir y continuamos a la siguiente sección con el botón
        “Continuar”.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_23.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Añadimos también los cafés e infusiones. Por último con el botón
        finalizar podemos finalizar la configuración del menú.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_24.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Ahora que hemos finalizado y tenemos todos los productos activos y
        correctamente dados de alta podemos activar el menú para que los
        clientes lo puedan seleccionar y pedir. Vamos a la página principal de
        los menús del día y hacemos clic sobre la imagen del menú. En la parte
        de abajo del formulario vamos a activar el menú con el switch “Activar
        el menú del día”, seguido pulsamos en el botón “Guardar” y listo,
        tenemos disponible nuestro menú.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_25.png"
        max-width="700"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        En la parte frontal los clientes lo verán de la siguiente forma.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_26.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <v-img
        src="./images/daily-menus/es.daily-menus_27.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        En la tarjeta del menú del día tenemos disponible el botón para eliminar
        un menú.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_28.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Al hacer clic sobre el botón eliminar, un mensaje de confirmación nos
        preguntará si queremos confirmar la acción y la posibilidad de cancelar.
        En el caso de que el menú tiene productos dados de alta tenemos que
        indicarle al sistema que operación queremos realizar, Eliminar todos los
        productos o bien moverlos a otro menú del día.
      </p>

      <v-img
        src="./images/daily-menus/es.daily-menus_29.png"
        max-width="500"
        class="mx-auto"
      ></v-img>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
