<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Categorías
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Categorías</h3>

      <p>
        La sección de categorías nos permite crear, modificar y eliminar
        categorías de nuestra carta. Las categorías son una forma de organizar
        nuestros productos. Podemos definir por ejemplo las siguientes
        categorías: “Cafés, Tés e Infusiones”, “Refrescos”, “Bebidas Frías”,
        “Desayunos”, “Platos Combinados”, “Bocadillos”, “Carnes”, “Pescados y
        Mariscos”, “Vinos”, “Sandwiches” etc… En función de los productos que
        ofrecemos.
      </p>

      <v-img
        src="./images/categories/es.categories_01.png"
        max-width="300"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si no tenemos ninguna categoría creada el sistema nos muestra el mensaje
      </p>

      <v-img
        src="./images/categories/es.categories_02.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5 font-italic">
        “No hay ninguna categoría actualmente en el sistema. Por favor, utilice
        el botón "añadir" que se encuentra abajo a la derecha para crear una
        categoría.”
      </p>

      <p>
        El boton añadir se encuentra en la parte derecha abajo, de color rojo
        con un “+” en el centro.
      </p>

      <v-img
        src="./images/categories/es.categories_03.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Al hacer clic sobre el botón vamos a poder introducir los datos de la
        nueva categoría.
      </p>

      <v-img
        src="./images/categories/es.categories_04.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        En la parte derecha podemos ver una vista previa en tiempo real de cómo
        verán los clientes la categoría. Según añadimos información y/o
        cambiamos opciones se podrán ver en esta pequeña vista previa.
      </p>

      <p>
        Los campos con asterisco (*) son obligatorios, en este caso solo el
        título. El primer campo en el formulario es la imágen de la categoría.
        Aquí siempre es recomendable añadir una imagen ya que llama mucho la
        atención del cliente.
      </p>

      <p>* La imagen no puede tener un tamaño superior a 512kb.</p>

      <p>
        En el título vamos a indicar el nombre de categoría, por ejemplo
        <strong>“Ensaladas”</strong> y en el apartado descripción un pequeño
        texto de lo que vamos a encontrar en dicha sección. Este campo es
        opcional por lo que se puede omitir.
      </p>

      <p>
        En el encabezado del formulario se nos indica el idioma para el cual se
        mostrará dicho texto. El primero es Español, y más abajo (solo si lo
        hemos configurado - mirar la configuración del sistema) aparecerá el
        inglés.
      </p>

      <p>
        Escribimos el nombre también en inglés <strong>“Salads”</strong>, si el
        cliente en su móvil selecciona el idioma inglés entonces verá el nombre
        que hemos indicado en este apartado.
      </p>

      <v-img
        src="./images/categories/es.categories_05.png"
        class="mx-auto"
        max-width="600"
      ></v-img>

      <p class="pt-5">
        Por último tenemos disponible una opción para activar o desactivar la
        categoría. Esto quiere decir que si está desactivada ningún cliente
        podrá ver nuestra categoría ni tampoco ningún producto dentro de la
        misma.
      </p>

      <v-img
        src="./images/categories/es.categories_06.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        En nuestro caso dejamos activada la categoría y hacemos clic sobre el
        botón guardar para crear la categoría.
      </p>

      <p>
        * El botón <strong>cancelar</strong> nos vuelve a la página anterior sin
        realizar ningún cambio y todos los datos introducidos se perderán.
      </p>

      <p>
        Una vez guardada la categoría, en la página principal de categorías
        vamos a ver una nueva “tarjeta” con los datos introducidos.
      </p>

      <v-img
        src="./images/categories/es.categories_07.png"
        class="mx-auto"
        max-width="400"
      ></v-img>

      <ol class="pt-5">
        <li>
          Es la posición de la categoría y el botón para reordenarlas, haciendo
          click sobre el botón y arrastrar la tarjeta podemos colocarla en
          cualquier posición.
        </li>
        <li>
          Indicador de visibilidad, si está activada se muestra un ojo, en caso
          contrario se muestra un ojo tachado.
        </li>
        <li>El nombre que le hemos dado a la categoría en español</li>
        <li>La descripción, en este caso lo habíamos dejado vacío.</li>

        <li>
          El botón para ver y administrar los productos asociados a esta
          categoría.
        </li>
        <li>El botón para eliminar la categoría.</li>
      </ol>

      <p class="pt-5">
        Si hacemos clic sobre la tarjeta (la imagen de la categoría) nos muestra
        de nuevo el formulario de la misma pero esta vez con los datos de la
        categoría que hemos pinchado. Esto nos sirve para actualizar algún dato
        o bien desactivarla si se necesita.
      </p>

      <p>
        El botón eliminar nos permite eliminar una categoría, si hacemos clic
        sobre el mismo nos muestra un mensaje de confirmación antes de finalizar
        la operación.
      </p>

      <v-img
        src="./images/categories/es.categories_08.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si la categoría tuviese productos, entonces el sistema nos preguntará
        qué hacer con dichos productos. Si los eliminamos o los movemos a otra
        categoría.
      </p>

      <v-img
        src="./images/categories/es.categories_09.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si en la lista seleccionamos “Platos Combinados” entonces al hacer clic
        sobre el botón eliminar los productos se moverán a la categoría “Platos
        combinados” y solo se elimina la categoría. Si por el contrario
        seleccionamos la opción “Eliminar todos los productos”, al hacer clic
        sobre
        <img src="./images/categories/es.categories_10.png" height="30px" />
        <span class="font-weight-medium red--text">
          se elimina la categoría junto con todos los productos contenidos en la
          misma.
        </span>
      </p>

      <p>
        Por último en la parte de abajo, de la página encontraremos los enlaces
        de paginación para poder ver todas las categorías.
      </p>

      <v-img
        src="./images/categories/es.categories_11.png"
        max-width="200"
        class="mx-auto"
      ></v-img>

      <h4 class="text-h6 pt-5">Preguntas frecuentes:</h4>
      <p class="pt-5 font-weight-medium">
        No veo la categoría en la aplicación móvil, ¿por qué?
      </p>

      <p>Esto se puede deber a dos factores:</p>

      <ol>
        <li class="pb-3">La visibilidad de la categoría está a “no activa”</li>
        <v-img
          src="./images/categories/es.categories_12.png"
          class="mx-auto"
        ></v-img>
        <li>
          La categoría no tiene ningún <strong>producto activo</strong>. Si no
          hay productos activos no mostraremos la categoría a los clientes ya
          que si pincha la categoría no verán ningún producto.
        </li>
      </ol>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
