<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Pedidos
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Pedidos</h3>

      <p>
        La parte de pedidos es donde vamos a recibir los pedidos de nuestros
        clientes.
      </p>

      <v-img
        src="./images/orders/es.orders_01.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Los pedidos se muestran ordenados primero por los más antiguos para
        darles prioridad a los clientes que más tiempo llevan esperando. Cada
        vez que un cliente nos llama (función llamar al camarero), solicita la
        cuenta o realiza un pedido, el sistema nos avisará de forma sonora y una
        nueva “tarjeta” aparecerá en esta sección. A parte de las tarjetas
        también se muestra el número de nuevos pedidos sin atender con una
        imagen de campana en el menú izquierdo de la aplicación.
      </p>

      <p>
        Los pedidos en estado “Nuevo” parpadean para indicar que aún no se han
        atendido, si un pedido lleva menos de 5 minutos estarán en verde, si se
        actualizó hace mas de 5 minutos pero menos de 10 entonces tendra un
        color amarillo y por encima de los 10 minutos parpadea en rojo.
      </p>

      <v-img src="./images/orders/es.orders_02.png" class="mx-auto"></v-img>

      <p class="pt-5">
        Como se ve en la imagen de arriba tenemos 3 tipos de tarjetas:
      </p>

      <ul>
        <li>Pedido (indicado por la imagen de bolsa de compras).</li>
        <li>
          Llamada al camarero (indicado por la imagen de una mano alzada).
        </li>
        <li>O solicitud de cuenta (indicado por una impresora de tickets).</li>
      </ul>

      <p class="pt-5">
        Con número grande se muestra la mesa desde donde se realizó dicho
        pedido, al lado del icono mostramos el estado y en la parte inferior
        algunos detalles como el tiempo que pasó desde que el cliente nos lo
        solicitó junto con precio en caso de pedido y tipo de pago en caso de
        solicitud de cuenta.
      </p>

      <p>
        Es importante saber que en esta sección solo se muestran los pedidos en
        estado <strong>“Nuevo”</strong> y estado
        <strong>“Aceptado / Pendiente”</strong>. En cuanto un pedido está en
        estado “Completado” o “Rechazado” desaparece de esta sección y solo lo
        vamos a poder encontrar en la parte de “Histórico (pedidos)”.
      </p>

      <p>Al clicar la tarjeta de un pedido se nos abren los detalles:</p>

      <v-img src="./images/orders/es.orders_03.png" class="mx-auto"></v-img>

      <p class="pt-5">
        En el ejemplo de arriba vemos que el cliente de la mesa 1 con el código
        <strong>CIX</strong> nos ha pedido una caña de cerveza, una Mahou sin
        alcohol y un menú 1 que contiene coca cola de bebida, ensalada cesar de
        primero, una hamburguesa de segundo y por último un café con hielo.
      </p>

      <p>
        En esta ventana podemos marcar los productos que ya hemos entregado al
        cliente y cambiar de estado del pedido a “Aceptado / Preparando” pulsar
        en el botón “Guardar”. Una vez guardado con el estado “Aceptado /
        Preparando” dejará de parpadear.
      </p>

      <v-img
        src="./images/orders/es.orders_04.png"
        max-width="400"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Muy útil para cuando un cliente solicite la cuenta, disponemos del botón
        “Pedidos de la mesa 1” que nos mostrará todos los pedidos. Este botón
        nos llevará al histórico con el filtro aplicado solo para
        <strong>pedidos no pagados de la mesa 1</strong>.
      </p>

      <v-img src="./images/orders/es.orders_05.png" class="mx-auto"></v-img>

      <p class="pt-5">
        El botón “Vista Ticket” nos muestra la cuenta actual del cliente
        juntando todos los productos pedidos y ocultando los detalles de los
        menús del día.
      </p>

      <v-img
        src="./images/orders/es.orders_06.png"
        class="mx-auto"
        max-width="400"
      ></v-img>

      <p class="pt-5">
        En esta nueva pantalla podemos volver a la vista del pedido (la
        anterior), marcar el pedido como pagado o cerrar estas vistas y volver
        al listado de pedidos.
      </p>

      <p>
        Al marcar un pedido como pagado cerrará dicho pedido y
        <strong>no podrá ser modificado</strong>. <br />
        Al mismo tiempo
        <strong>
          todos los clientes autorizados de la mesa donde estaba el pedido serán
          desautorizados automáticamente.
        </strong>
      </p>

      <p>
        Por último en la parte inferior vamos a tener disponibles los enlaces
        para la paginación.
      </p>

      <v-img
        src="./images/orders/es.orders_07.png"
        class="mx-auto"
        max-width="300"
      ></v-img>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
