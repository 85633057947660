<template>
  <v-app>
    <v-system-bar app height="38" color="error darken-1" v-if="!isConnected">
      <v-icon color="error lighten-5" dark> mdi-lan-disconnect </v-icon>
      <span class="red--text text--lighten-5 ml-2">
        Se ha producido un error con la conexión al sistema de pedidos.
      </span>
    </v-system-bar>

    <v-navigation-drawer v-model="drawer" app dark color="blue darken-1">
      <v-toolbar color="blue darken-1" flat class="mt-2">
        <v-select
          :items="restaurants"
          item-text="name"
          item-value="uuid"
          label="Restaurantes Registrados"
          prepend-icon="mdi-store"
          class="mt-3"
          :value="selected"
          @change="changed"
        ></v-select>
      </v-toolbar>

      <v-list dense>
        <v-list-item
          :to="{
            name: 'clients',
            params: { restaurant: currentSelectedRestaurant },
          }"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-account-question</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('navigation.clients')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'orders',
            params: { restaurant: currentSelectedRestaurant },
          }"
          exact
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-shopping</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('navigation.orders') }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="orders.length > 0">
            <v-chip color="green" dark small class="elevation-2">
              <v-icon small left color="white">mdi-bell</v-icon>
              {{ orders.length }}
            </v-chip>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'history',
            params: { restaurant: currentSelectedRestaurant },
          }"
          exact
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-history</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('navigation.history')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-3"></v-divider>

        <v-list-item
          :to="{
            name: 'categories',
            params: { restaurant: currentSelectedRestaurant },
          }"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-shape</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('navigation.categories')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'daily-menus',
            params: { restaurant: currentSelectedRestaurant },
          }"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-book-open-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('navigation.dailyMenus')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'allergens',
            params: { restaurant: currentSelectedRestaurant },
          }"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-peanut-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('navigation.allergens')
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-3"></v-divider>

        <v-list-group
          id="nav-items-restaurant"
          nav
          prepend-icon="mdi-store"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Restaurante</v-list-item-title>
          </template>

          <v-list-item
            :to="{
              name: 'contact-information',
              params: { restaurant: currentSelectedRestaurant },
            }"
            exact
            link
          >
            <v-list-item-title>{{
              $t('navigation.contactInformation')
            }}</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-badge-account-horizontal</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            :to="{
              name: 'employees',
              params: { restaurant: currentSelectedRestaurant },
            }"
            exact
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{
                $t('navigation.employees')
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-account-supervisor</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            :to="{
              name: 'configuration',
              params: { restaurant: currentSelectedRestaurant },
            }"
            exact
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{
                $t('navigation.configuration')
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            :to="{
              name: 'tables',
              params: { restaurant: currentSelectedRestaurant },
            }"
            exact
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{
                $t('navigation.tables')
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-table-chair</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <!-- TODO: Annadir la funcionalidad de feedback.
          <v-list-item
            :to="{ name: 'feedback', params: { restaurant: currentSelectedRestaurant } }"
            exact
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t('navigation.feedback') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-message-draw</v-icon>
            </v-list-item-icon>
          </v-list-item>-->
        </v-list-group>

        <v-divider class="my-3"></v-divider>

        <!--Facturas y cobros, solo admins y dueños -->
        <v-list-group
          v-if="isAdmin || isEmployee || isOwner"
          id="nav-items-restaurant"
          nav
          prepend-icon="mdi-storefront"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Cobros / Facturas</v-list-item-title>
          </template>

          <v-list-item
            :to="{
              name: 'payment-method',
              params: { restaurant: currentSelectedRestaurant },
            }"
            exact
            link
          >
            <v-list-item-title>
              {{ $t('navigation.paymentMethod') }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-credit-card-multiple</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            :to="{
              name: 'subscriptions',
              params: { restaurant: currentSelectedRestaurant },
            }"
            exact
            link
          >
            <v-list-item-title>
              {{ $t('navigation.subscriptions') }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-cube-send</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            :to="{
              name: 'invoices',
              params: { restaurant: currentSelectedRestaurant },
            }"
            exact
            link
          >
            <v-list-item-title>
              {{ $t('navigation.invoices') }}
            </v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-file-download-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <!-- Facturas y cobros, solo admins y dueños -->

        <!-- PARTE DE ADMINISTRACION DE RESTAURANTES -->
        <v-divider v-if="isAdmin || isEmployee" class="my-3"></v-divider>

        <v-list-group
          id="nav-items-administration"
          v-if="isAdmin || isEmployee"
          prepend-icon="mdi-google-my-business"
          :value="false"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Administracion</v-list-item-title>
          </template>

          <v-list-item :to="{ name: 'administration.restaurants' }" exact link>
            <v-list-item-title>Restaurantes</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-store</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item :to="{ name: 'administration.packs' }" exact link>
            <v-list-item-title>Packs Mesas</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-package-variant</v-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            :to="{
              name: 'employees',
              params: { restaurant: currentSelectedRestaurant },
            }"
            exact
            link
          >
            <v-list-item-content>
              <v-list-item-title>Idiomas</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-translate</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <v-list dense>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-git</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <!-- GIT VERSION -->
              <v-list-item-title>
                {{ version }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="white" flat>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title> EasyServe Administración </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon large @click="showHelp = true">
        <v-icon large>mdi-help-circle-outline</v-icon>
      </v-btn>

      <v-menu bottom left min-width="200">
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-icon large>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!--
          TODO: Añadir el perfil del cliente y las opciones (basicamente cambio de password)  
          <v-list-item @click="click" to="/profile">
            <v-list-item-title class="pl-2">Perfil</v-list-item-title>
          </v-list-item>
          <v-list-item @click="click" to="/settings">
            <v-list-item-title class="pl-2">Configuración</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>-->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="pl-2">{{ username }}</v-list-item-title>
              <v-list-item-subtitle class="pl-2">
                {{ email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item @click="logout">
            <v-list-item-title class="pl-2">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- Global router view -->
    <router-view></router-view>

    <v-footer dark padless app inset absolute>
      <v-card
        width="100%"
        flat
        tile
        class="grey darken-3 white--text text-center"
      >
        <v-card-text class="white--text">
          &copy; {{ new Date().getFullYear() }} —
          <strong>EasyServe</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <!-- Global Manual for every page to get access and only one instance -->
    <EasyServeManual
      :showHelp="showHelp"
      @closeHelp="showHelp = false"
    ></EasyServeManual>

    <!-- Global snackbar for every component to use -->
    <eximyr-snackbar></eximyr-snackbar>
  </v-app>
</template>

<script>
import { MEDIA_BASE, EASYSERVE_VERSION } from '@/constants';
import { mapGetters } from 'vuex';

import EximyrSnackbar from '../components/EximyrSnackbar';
import EasyServeManual from './documentation/EasyServeManual';

export default {
  components: {
    EximyrSnackbar,
    EasyServeManual,
  },

  data: () => ({
    drawer: null,

    clientsNotification: 1,
    ordersNotification: 1,

    // Show manual
    showHelp: false,

    // The sound to play when a users scans a code or place an order...
    // TODO: Make it configurable
    audio: new Audio(MEDIA_BASE + '/sounds/notification_01.wav'),
  }),

  computed: {
    ...mapGetters('security', {
      user: 'user',
      isAdmin: 'isAdmin',
      isEmployee: 'isEmployee',
      isOwner: 'isOwner',
    }),

    ...mapGetters('system', {
      isConnected: 'isConnected',
    }),

    ...mapGetters('restaurants', {
      restaurants: 'restaurants',
      restaurant: 'restaurant',
      selected: 'selected',
      loading: 'loading',
    }),

    ...mapGetters('orders', {
      pending: 'pending',
      loadingOrders: 'loading',
    }),

    username() {
      if (this.user) {
        return this.user.first_name + ' ' + this.user.first_surname;
      }
      return '';
    },

    email() {
      return this.user ? this.user.email : '';
    },

    version() {
      // The new version of the software.
      return EASYSERVE_VERSION;
    },

    orders() {
      return this.pending.filter(o => o.status == 'new');
    },

    /**
     * There is a problem when an administrator goes to the restaurants list
     * that is there is no params.restaurant in the URL anymore so we must
     * return the selected restaurant.
     */
    currentSelectedRestaurant() {
      return this.$route.params.restaurant
        ? this.$route.params.restaurant
        : this.selected;
    },
  },

  created() {
    this.loadAndSelect(this.$route.params.restaurant);
  },

  methods: {
    logout() {
      this.$store.dispatch('security/logout').then(() => {
        this.leave(this.$route.params.restaurant);
        this.$router.push('/login');
      });
    },

    changed(restaurant) {
      // window.events.$emit('restaurant-changed', event);
      // Leave selected restaurant and start listening to the current one
      this.leave(this.selected);
      this.listenTo(restaurant);

      // This restaurant must be in the restaurants list
      this.$store.dispatch('restaurants/select', restaurant);

      // Load pending orders
      this.$store.dispatch('orders/loadPending', { restaurant, page: 1 });

      // Redirect to the new restaurant orders
      return this.$router.push({
        name: 'orders',
        params: {
          restaurant,
        },
      });
    },

    loadAndSelect(restaurant = null) {
      this.$store.dispatch('restaurants/load').then(response => {
        // If the restaurant is null
        if (restaurant == null) {
          // Select the first one
          restaurant = response.data[0].uuid;
        }

        // IF WE ARE ADMINS WE ALLOW THE SELECTED RESTAURANT
        if (
          !this.restaurants.find(r => r.uuid === restaurant) &&
          (this.isAdmin || this.isEmployee)
        ) {
          // WE are admins so we MUST have access here ...

          // First lets load the restaurant
          this.$store
            .dispatch('restaurants/loadRestaurant', restaurant)
            .then(response => {
              // We must add the current restaurant to our "associated restaurants"
              this.$store.dispatch('restaurants/addToList', response);

              // Load orders
              this.$store.dispatch('orders/loadPending', {
                restaurant,
                page: 1,
              });

              // Listen for orders
              this.listenTo(restaurant);

              // Now we can change the title for the current restaurant
              this.setTitle();
            });

          return;
        }

        // Try to select the restaurant in the URL
        if (this.restaurants.find(r => r.uuid === restaurant)) {
          this.$store.dispatch('restaurants/select', restaurant);

          // Load orders
          this.$store.dispatch('orders/loadPending', { restaurant, page: 1 });

          // Listen for orders
          this.listenTo(restaurant);

          // Now we can change the title for the current restaurant
          this.setTitle();
        } else {
          // Go to 404, there is no restaurant for you here ...
          return this.$router.push('/404');
        }
      });
    },

    listenTo(restaurant) {
      // Listen for echo
      const echo = window.Echo;

      // Presence channel - works for chat like, not to cound users.
      // echo
      //   .join('online.' + restaurant)
      //   .here(users => {
      //     // If the current user is already connected we cannot connect again
      //     let user = users.find(user => user.id === this.user.id);
      //     if (user != null) {
      //       console.log('You are already connected!');
      //     } else {
      //       console.log('You are now connected to the system!');
      //     }
      //     console.log('users: ', users);
      //   })
      //   .joining(user => {
      //     console.log('Joining: ', user);
      //   })
      //   .leaving(user => {
      //     console.log('Leaving ', user);
      //   });

      this.listenForEvents();

      echo.private('orders.' + restaurant).listen('OrderStatusUpdated', e => {
        // Play a sound only if it's configured
        if (this.restaurant.config.sound_on_order) {
          this.playAudio();
        }
        this.$store.dispatch('orders/injectOrder', e.order);
      });

      echo.private('clients.' + restaurant).listen('ClientCreated', e => {
        // Play a sound only if it's configured
        if (this.restaurant.config.sound_on_scan) {
          this.playAudio();
        }
        this.$store.dispatch('clients/inject', e.client);
      });
    },

    leave(restaurant) {
      const echo = window.Echo;
      echo.leave('orders.' + restaurant);
      echo.leave('clients.' + restaurant);
      this.$store.dispatch('orders/clear');
      // console.log('Leaving ' + 'orders.' + restaurant);
      // console.log('Leaving ' + 'clients.' + restaurant);
    },

    playAudio() {
      this.audio.play().catch(error => {
        console.error(error);
        this.audio.play().catch(error => console.error(error));
      });
    },

    listenForEvents() {
      window.Echo.connector.pusher.connection.bind('initialized', () => {
        this.$store.dispatch('system/disconnect');
        console.error('Pusher: initialized');
      });

      window.Echo.connector.pusher.connection.bind('connecting', () => {
        this.$store.dispatch('system/disconnect');
        console.error('Pusher: connecting');
      });

      window.Echo.connector.pusher.connection.bind('connected', () => {
        this.$store.dispatch('system/connect');
        console.error('Pusher: connected');
      });

      window.Echo.connector.pusher.connection.bind('unavailable', () => {
        this.$store.dispatch('system/disconnect');
        console.error('Pusher: unavailable');
      });

      window.Echo.connector.pusher.connection.bind('failed', () => {
        this.$store.dispatch('system/disconnect');
        console.error('Pusher: failed');
      });

      window.Echo.connector.pusher.connection.bind('disconnected', () => {
        this.$store.dispatch('system/disconnect');
        console.error('Pusher: disconnected');
      });
    },

    setTitle() {
      window.document.title = `EasyServe - ${this.restaurant.name}`;
    },
  },
};
</script>

<style>
/**
 * Es el estilo de la navegacion activa en color blanco

.v-application .primary--text {
  color: #fff !important;
  caret-color: #fff !important;
} */

#nav-items-restaurant,
#nav-items-administration {
  color: #fff !important;
  caret-color: #fff !important;
}
</style>
