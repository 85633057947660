<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Autorizaciones (Clientes)
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Autorizaciones</h3>

      <v-img
        src="./images/authorization/es.authorization_01.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        La opción <strong>Autorizaciones</strong> permite autorizar o
        desautorizar a un cliente a realizar pedidos a través de su móvil.
        Siempre que un cliente escanea el código QR situado en sus mesas se le
        asigna un código aleatorio de entre 3 y 5 letras y números y se mostrará
        aquí con estado “No autorizado”. Esto quiere decir que dicho cliente no
        puede realizar pedidos, solo tiene permisos para ver la carta.
      </p>

      <p>
        Esta funcionalidad es muy importante dado que en las mesas lo que
        tenemos son códigos QR que cualquier cliente puede fotografiar y llevar
        consigo para que el día de mañana escanee y haga pedidos desde donde
        quiera que estén. Para solucionar este problema hemos implementado el
        sistema de autorización por el camarero, esto se realiza únicamente
        cuando el cliente se sienta en la mesa y autoriza al cliente a realizar
        pedidos durante una o varias horas (Es configurable en la parte de
        configuración).
      </p>

      <p>
        Cada vez que un nuevo cliente escanea la mesa, recibiremos un aviso
        sonoro (si está configurado) en los dispositivos conectados. Es muy útil
        si disponemos de una terraza larga, la cual no está del todo controlada.
      </p>

      <v-img
        src="./images/authorization/es.authorization_02.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Podemos filtrar a los clientes por la mesa donde están sentados o bien
        por su estado (Autorizado, No autorizado, Cualquiera).
      </p>

      <v-img
        src="./images/authorization/es.authorization_03.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        En la tabla situada debajo del formulario de filtrado se muestran los
        clientes con sus correspondientes datos (estado en formato imagen,
        número de mesa, hora cuando han escaneado el código, clave de acceso y
        el estado en formato texto).
      </p>

      <v-img
        src="./images/authorization/es.authorization_04.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        En el ejemplo de arriba tenemos a 2 clientes en la mesa 5 y 2 clientes
        en la mesa 3. Solo un cliente de la mesa 5 y un cliente de la mesa 3
        tienen permisos para realizar pedidos.
      </p>

      <p>
        Para autorizar a un cliente vamos
        <strong>primero a preguntarle qué código tiene asignado</strong> en su
        teléfono (una vez que ha escaneado nuestro código de mesa). Esto lo
        puede ver bien en el carrito o en la parte de llamar al camarero.
      </p>

      <v-row>
        <v-col cols="12" sm="6">
          <v-img
            src="./images/authorization/es.authorization_05a.png"
            class="mx-auto"
            max-width="300"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="6">
          <v-img
            src="./images/authorization/es.authorization_05b.png"
            class="mx-auto"
            max-width="300"
          ></v-img>
        </v-col>
      </v-row>

      <p class="pt-5">
        Buscamos el cliente según la mesa donde está sentado y haciendo click
        sobre su código nos abre la ventana para autorizar y/o cambiar de mesa.
      </p>

      <v-img
        src="./images/authorization/es.authorization_06.png"
        class="mx-auto"
        max-width="600"
      ></v-img>

      <p class="pt-5">
        Marcamos la casilla <strong>“Autorizado”</strong> y guardamos con el
        botón guardar. A partir de este momento el cliente podrá realizar
        pedidos sin necesidad de interacción con el camarero y sus pedidos
        llegarán directamente a la barra o a los dispositivos conectados (puede
        haber varios).
      </p>

      <p>
        Para desautorizar un cliente realizamos el mismo proceso de
        identificación hacemos click sobre la fila, desmarcamos el check de
        autorizado y guardamos.
      </p>

      <v-img
        src="./images/authorization/es.authorization_07.png"
        class="mx-auto"
        max-width="600"
      ></v-img>

      <p class="pt-5">
        Por último, en la parte inferior encontraremos los botones para la
        paginación.
      </p>
      <v-img
        src="./images/authorization/es.authorization_08.png"
        class="mx-auto"
        max-width="300"
      ></v-img>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
