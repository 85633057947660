<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Restaurante - Empleados
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Restaurante - Empleados</h3>

      <p>
        Aquí vamos a poder administrar los empleados de nuestro restaurante.
      </p>

      <v-img
        src="./images/employees/es.employees_01.png"
        max-width="400"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Al pulsar en el menú de empleados nos mostrará todos los empleados
        existentes en el sistema.
      </p>

      <v-img
        src="./images/employees/es.employees_02.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Podemos filtrar los resultados por DNI, Nombre / Apellidos y/o correo
        electrónico, una vez ingresados los datos pulsaremos en el botón
        “Buscar” para realizar la búsqueda. Si el filtro no arroja resultados
        vamos a ver en la tabla el mensaje “No hay ningún empleado para los
        criterios de búsqueda.”.
      </p>

      <v-img
        src="./images/employees/es.employees_03.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si pulsamos el botón “Limpiar Filtros” se eliminarán todos los datos del
        formulario. En la tabla vamos a ver los siguientes datos de nuestros
        empleados:
      </p>

      <v-img
        src="./images/employees/es.employees_04.png"
        class="mx-auto"
      ></v-img>

      <ol class="py-5">
        <li>Nombre completo (nombre y apellidos)</li>
        <li>DNI / NIE (este dato es opcional)</li>
        <li>E-Mail (correo electrónico)</li>
        <li>Grupo (camarero / dueño)</li>
      </ol>

      <p>
        Si queremos actualizar los datos de alguno de los empleados hacemos clic
        en la tabla sobre el empleado que deseamos modificar y a continuación,
        en el formulario presentado actualizamos los datos deseados.
      </p>

      <v-img
        src="./images/employees/es.employees_05.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Una vez finalizada la actualización de datos pulsamos en “Guardar” para
        grabar los datos o bien en “Cancelar” para descartar los cambios y
        volver a la pantalla anterior (listado de empleados).
      </p>

      <p>
        El formulario tiene algunas validaciones que se deben cumplir antes de
        guardar los cambios en la base de datos. Por ejemplo, el nombre del
        empleado es obligatorio, el correo debe ser válido, etc … Estas
        validaciones se indicarán en color rojo si fallan.
      </p>

      <p>
        En el siguiente ejemplo vemos como el nombre del empleado es obligatorio
        y el correo electrónico nos indica que no es válido.
      </p>

      <v-img
        src="./images/employees/es.employees_06.png"
        class="mx-auto"
      ></v-img>

      <h4 class="text-h5 pt-10 pb-3">Alta de empleados</h4>

      <p>
        Es recomendable que cada camarero disponga de su propio usuario y
        contraseña, de esta forma se podrá saber quién ha realizado que pedido o
        que acción.
      </p>

      <p>
        Podemos crear un nuevo empleado utilizando el botón rojo flotante con
        simbolo “+” que se encuentra en la parte derecha abajo.
      </p>

      <v-img
        src="./images/employees/es.employees_07.png"
        max-width="80"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si pulsamos este botón se nos muestra la ventana de creación de
        empleado. Es prácticamente la misma ventana que la edición de empleados.
      </p>

      <v-img
        src="./images/employees/es.employees_08.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Tanto los campos como las validaciones son las mismas que en caso de
        modificación de empleado. Si tenemos algún dato inválido nos lo indicará
        en el formulario en color rojo.
      </p>

      <p>
        Una vez finalizada el alta del cliente el sistema enviará un correo
        electrónico al empleado para que active su cuenta. El empleado debe
        activar su cuenta y elegir la contraseña en el momento de activación, de
        esta manera nadie podrá saber que contraseña ha utilizado.
      </p>

      <p>
        Es importante asegurarnos de que el correo electrónico es válido y que
        el empleado tiene acceso al mismo para reseteo de contraseña por
        ejemplo.
      </p>

      <p>
        Por último, debajo de la tabla vamos a encontrar los botones de
        paginación.
      </p>

      <v-img
        src="./images/employees/es.employees_09.png"
        max-width="300"
        class="mx-auto"
      ></v-img>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
