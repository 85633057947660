<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Histórico (pedidos)
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Histórico de pedidos</h3>

      <p>
        El histórico de pedidos contiene la información de todos los pedidos del
        restaurante es donde vamos a poder revisar cualquier pedido con
        cualquier estado y desde cualquier día.
      </p>

      <v-img
        src="./images/history/es.history_01.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">Podemos filtrar nuestros pedidos por:</p>

      <ul>
        <li>Tipo de pedido (pedido, la cuenta o llamada al camarero).</li>
        <li>
          Estado del pedido (nuevo, aceptado / preparando, rechazado y
          completado)
        </li>
        <li>Número de mesa (cualquier mesa asociada al restaurante)</li>
        <li>Pedido pagado (si, no o cualquier estado)</li>
        <li>Fecha desde (el día desde que se desea buscar)</li>
        <li>Fecha hasta (el día hasta que se desea buscar)</li>
      </ul>

      <v-img src="./images/history/es.history_02.png" class="mx-auto"></v-img>

      <p class="pt-5">
        El botón “Limpiar filtros” nos permite eliminar todos los filtros
        seleccionados. El botón buscar aplica el filtro indicado y realiza la
        búsqueda.
      </p>

      <p>
        Debajo del filtro se presenta la tabla con los resultados de la
        búsqueda, si no hay resultados para los parámetros buscados nos mostrará
        el mensaje “Actualmente no hay ningún pedido pendiente.”
      </p>

      <v-img src="./images/history/es.history_03.png" class="mx-auto"></v-img>

      <p class="pt-5">
        Si el filtro arroja resultados entonces vamos a ver un check-box para
        marcar el pedido, el número de la mesa, fecha y hora cuando se realizó,
        indicador de pedido pagado (solo para pedidos), estado y detalles
        extras.
      </p>

      <v-img src="./images/history/es.history_04.png" class="mx-auto"></v-img>

      <p class="pt-5">
        Si hacemos clic sobre una fila se nos abrirá la ventana con el detalle
        del pedido seleccionado donde vamos a poder cambiar el estado y marcar
        los productos ya servidos al cliente.
      </p>

      <v-img src="./images/history/es.history_05.png" class="mx-auto"></v-img>

      <p class="pt-5">
        Desde la misma tabla podemos marcar varios pedidos utilizando los
        checkbox de la izquierda y marcarlos todos como pagados utilizando el
        botón “Pagados” que se encuentra en la parte inferior de la tabla.
      </p>

      <v-img src="./images/history/es.history_06.png" class="mx-auto"></v-img>

      <p class="pt-5 font-weight-medium">
        Es muy importante marcar pedido pagado ya que sino los siguientes serán
        añadidos a este en vez de a una nueva cuenta.
      </p>

      <p>
        Por último, debajo de la tabla vamos a encontrar los botones de
        paginación.
      </p>

      <v-img
        src="./images/history/es.history_07.png"
        max-width="300"
        class="mx-auto"
      ></v-img>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
