<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Restaurante - Datos de Contacto
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Restaurante - Datos de Contacto</h3>

      <p>
        En este apartado vamos a tener los datos de contacto del restaurante
        junto con el nombre y algunos datos adicionales.
      </p>

      <p>
        El nombre se utiliza en la carta pública a los clientes, todos los demás
        datos son de uso interno de EasyServe para poder contactar con el
        restaurante.
      </p>

      <v-img src="./images/contact/es.contact_01.png" class="mx-auto"></v-img>

      <p class="pt-5">
        En el apartado “Información de Contacto” vamos a poder modificar el
        nombre del restaurante y el número de teléfono, el NIF solo lo puede
        cambiar a través de nosotros, en el alta del restaurante se informa este
        dato.
      </p>

      <p class="pt-5">
        Debajo tenemos los datos de localización del Restaurante, la dirección
        completa del mismo.
      </p>

      <p>
        Una vez actualizados los datos pulsamos el botón Guardar para grabar.
      </p>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
