<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Productos
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Productos</h3>

      <p>
        Todos los productos del sistema deben estar asociados a una categoría
        por lo que para verlos tenemos que primero ver las categorías y hacer
        click sobre el botón de productos en la categoría que deseamos. Por
        ejemplo, vamos a crear nuevas ensaladas en la categoría Ensaladas.
      </p>

      <v-img
        src="./images/products/es.products_01.png"
        max-width="400"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Hacemos clic en el menú principal en “Categorías / Productos” y después
        en el botón azul de productos de una categoría. Sí no hay categorías
        visibles y no sabemos como hacerlo por favor revisar el
        <strong>manual de categorías</strong>.
      </p>

      <p>
        En la siguiente página vamos a ver todos los productos de dicha
        categoría. En nuestro caso no tenemos ningún producto. El sistema nos
        indica con un mensaje informativo que
      </p>

      <p class="font-italic">
        “No hay ningún producto actualmente en el sistema. Por favor, utilice el
        botón "añadir" que se encuentra abajo a la derecha para crear uno.”
      </p>

      <v-img src="./images/products/es.products_02.png" class="mx-auto"></v-img>

      <p class="pt-5">
        Vamos a crear un nuevo producto usando el botón “Añadir”, se encuentra
        en la parte derecha abajo, rojo con el símbolo “+” en el centro.
      </p>

      <v-img src="./images/products/es.products_03.png" class="mx-auto"></v-img>

      <p class="pt-5">
        En el primer campo podemos seleccionar una imagen a subir desde nuestro
        dispositivo (no mayor de 512kb).
      </p>

      <p>
        A continuación, al igual que en caso de las categorías, el formulario
        nos muestra el título y la descripción a rellenar por cada idioma
        configurado. Escribimos en el título “Ensalada César” y una descripción
        de lo que lleva tanto para español como para inglés (si así lo tenemos
        configurado).
      </p>

      <v-img src="./images/products/es.products_04.png" class="mx-auto"></v-img>

      <p class="pt-5">
        A continuación tenemos que elegir los alérgenos, la ensalada césar
        contiene “Cereales con gluten”, “Pescado”, “Lácteos”, “Huevos” y
        “Mostaza”. Los alergenos son muy importantes para cumplir con el
        <a
          href="https://eur-lex.europa.eu/legal-content/ES/TXT/?uri=celex%3A32011R1169"
          target="_blank"
        >
          Reglamento Europeo 1169/11
        </a>
        sobre la información alimentaria.
      </p>

      <v-img
        src="./images/products/es.products_05.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Seguimos con el precio del producto en céntimos, por ejemplo si el
        precio de la ensalada es 10,50€ en el campo precio vamos a indicar 1050
        sin la coma. El sistema mostrará el precio de forma correcta. Para
        revisar podemos echar un vistazo a la vista previa, en la esquina
        superior derecha se mostrará el precio introducido.
      </p>

      <v-img
        src="./images/products/es.products_06.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Y seleccionamos la visibilidad del producto con el check “Activar el
        producto”.
      </p>

      <v-img
        src="./images/products/es.products_07.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si no queremos que en este momento se pueda pedir este producto lo
        desactivamos, lo mismo podemos hacer cuando nos quedamos sin dicho
        producto. Vamos a los detalles del producto y lo desactivamos.
      </p>

      <p>
        Ahora hacemos clic sobre el botón <strong>Guardar</strong> para crear y
        persistir los datos o en <strong>Cancelar</strong> para descartar los
        cambios. Ten en cuenta que una vez cancelado no se pueden recuperar los
        datos del producto, se deberán volver a introducir.
      </p>

      <p>
        Ahora que tenemos una categoría y un producto, si escaneamos el código
        QR de una mesa vamos a poder ver dicha categoría,
      </p>

      <v-img
        src="./images/products/es.products_08.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Y si pinchamos en la misma, veremos nuestro producto con su imagen,
        nombre, precio, descripción y alérgenos.
      </p>

      <v-img
        src="./images/products/es.products_09.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Con el producto guardado correctamente si volvemos a la página de
        productos de la categoría “Ensaladas” se nos mostrará la tarjeta con el
        nuevo producto.
      </p>

      <v-img src="./images/products/es.products_10.png" class="mx-auto"></v-img>

      <p class="pt-5">
        Vamos a definir cada dato que se muestra en la tarjeta del producto.
      </p>

      <v-img
        src="./images/products/es.products_11.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <ol>
        <li>
          El orden de visibilidad del producto y botón para reordenar. Si
          hacemos clic sobre el mismo y arrastramos podemos reposicionar el
          producto.
        </li>
        <li>
          Indicador de si el producto está activo (es visible por los clientes)
          o no (no se mostrará a los clientes). Un ojo tachado indica que no
          será visible.
        </li>
        <li>
          Precio del producto correctamente formateado con sus centimos y el
          símbolo de euro.
        </li>

        <li>Nombre del producto en español.</li>

        <li>
          Descripción del producto (o parte de la descripción) en español.
        </li>

        <li>Los alérgenos que tiene asociado este producto.</li>
        <li>El botón eliminar para borrar este producto de nuestra carta.</li>
      </ol>

      <p class="pt-5">
        Para cambiar (modificar) cualquier dato de un producto guardado hacemos
        clic sobre la tarjeta del mismo (sobre la imagen por ejemplo).
      </p>

      <v-img
        src="./images/products/es.products_12.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Al clicar sobre la imagen nos muestra el formulario con los datos del
        producto que podemos modificar.
      </p>

      <v-img src="./images/products/es.products_13.png" class="mx-auto"></v-img>

      <p class="pt-5">
        El formulario tiene los mismos campos que en la sección de alta de
        producto, una vez modificado podemos guardarlo o bien cancelar la
        modificación con los botones de abajo “Cancelar” y “Guardar”.
      </p>

      <v-img
        src="./images/products/es.products_14.png"
        max-width="400"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Para eliminar un producto vamos a utilizar el botón borrar de la tarjeta
        del producto. Si hacemos clic sobre el mismo la aplicación nos pregunta
        si realmente queremos eliminar el producto. Con el botón “Eliminar” se
        elimina el producto y con el “Cancelar” podemos anular la acción de
        borrado.
      </p>

      <v-img
        src="./images/products/es.products_15.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <h5 class="text-h5 py-5">Validación de datos</h5>

      <p>
        Todos los formularios del sistema tienen implementado una validación de
        los datos introducidos para ayudarnos a mejorar la experiencia del
        cliente. Si hay errores de validación se mostrarán los mensajes en rojo
        en cada campo.
      </p>

      <v-img src="./images/products/es.products_16.png" class="mx-auto"></v-img>

      <p class="pt-5">
        En el ejemplo de arriba he seleccionado una imagen con un peso de 4MB
        por lo que el sistema no me lo permite. Al mismo tiempo he borrado el
        nombre del producto que es obligatorio.
      </p>

      <p class="font-weight-medium">
        El tamaño de las imágenes está restringido porque estas mismas imágenes
        serán descargadas por los móviles de los clientes, si tenemos por
        ejemplo 10 productos en una categoría y cada uno tiene una imagen de
        1MB, el cliente tiene que descargar 10 MB para ver nuestra carta. Dado
        que se trata de conexiones móviles lo mejor es que nuestras imágenes no
        pasen de 100 KB - lo podemos conseguir utilizando compresores de
        imágenes y editores para hacerlas más pequeñas.
      </p>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
