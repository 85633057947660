<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Alérgenos
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Alérgenos</h3>

      <p>
        En la sección de Alérgenos vamos a poder ver, crear, modificar y
        eliminar los alérgenos de nuestro restaurante.
      </p>

      <v-img
        src="./images/allergens/es.allergens_01.png"
        max-width="500"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Por defecto ya tenemos disponibles los 14 alérgenos obligatorios por el
        <a
          href="https://eur-lex.europa.eu/legal-content/ES/TXT/?uri=celex%3A32011R1169"
          target="_blank"
        >
          Reglamento Europeo 1169/11
        </a>
        pero podemos añadir más si lo necesitamos.
      </p>

      <v-img
        src="./images/allergens/es.allergens_02.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Para crear un nuevo alergeno vamos a utilizar el botón “Añadir” que
        encontraremos en la parte derecha abajo, rojo con el símbolo “+” en el
        centro.
      </p>

      <v-img
        src="./images/allergens/es.allergens_03.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Al clicar sobre el botón se nos muestra el formulario de alta de
        alérgenos. El primer campo es para la imagen que se va a mostrar en
        nuestros productos por lo que es obligatoria. A continuación tenemos que
        indicar un nombre y una descripción del alérgeno. En función de la
        configuración del sistema, tendremos que indicar el nombre y la
        descripción en varios idiomas (ver el menú
        <strong>configuración</strong> para más detalles).
      </p>

      <v-img
        src="./images/allergens/es.allergens_04.png"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Los alérgenos, igual que todos los productos, categorías y menús del día
        se pueden desactivar para que no estén visibles para los clientes.
        Podemos desactivar el alergeno con el switch “Activar el alérgeno”.
      </p>

      <v-img
        src="./images/allergens/es.allergens_05.png"
        class="mx-auto"
        max-width="600"
      ></v-img>

      <p class="pt-5">
        Por último vamos a guardar los cambios con el botón Guardar. Si queremos
        descartar los cambios y volver al listado usaremos el botón cancelar.
      </p>

      <p>
        * Al desactivar un alérgeno no estará disponible para los clientes pero
        sí estará visible y disponible a utilizar en la parte de administración.
      </p>

      <p>
        En la página principal de los alérgenos, en cada tarjeta de cada
        alergeno podemos actualizar el alérgeno haciendo click sobre la tarjeta
        o eliminar el alergeno con el botón “Eliminar”.
      </p>

      <v-img
        src="./images/allergens/es.allergens_06.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Para reordenar los alérgenos hacemos click y arrastramos el boton de
        posicionamiento localizado en la parte inferior izquierda como en la
        imagen de abajo.
      </p>

      <v-img
        src="./images/allergens/es.allergens_animated.gif"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si hacemos clic sobre el nombre Apio por ejemplo nos permite actualizar
        los datos del alergeno utilizando el mismo formulario de alta.
      </p>

      <v-img
        src="./images/allergens/es.allergens_07.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si queremos eliminar un alergeno lo podemos hacer clicando sobre el
        botón eliminar.
      </p>

      <v-img
        src="./images/allergens/es.allergens_08.png"
        max-width="60"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Si el alergeno está asociado a algún producto el sistema nos avisará con
        un mensaje de confirmación. “Ten en cuenta que hay 7 producto(s) que
        dejará(n) de tener este alérgeno asociado.”
      </p>

      <v-img
        src="./images/allergens/es.allergens_09.png"
        max-width="600"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Para confirmar el borrado presionamos el botón “ELIMINAR” o para
        cancelar la acción el botón “CANCELAR”.
      </p>

      <p>
        En la parte inferior de la página tenemos disponibles los enlaces de
        paginación.
      </p>

      <v-img
        src="./images/allergens/es.allergens_10.png"
        max-width="300"
        class="mx-auto"
      ></v-img>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
