<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      Restaurante - Configuración
      <template v-slot:actions>
        <v-icon color="primary"> $expand </v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <h3 class="text-h6 pb-5">Restaurante - Configuración</h3>

      <p>
        En esta sección se pueden modificar las preferencias de sonidos, tiempos
        de sesión y de idioma.
      </p>

      <v-img
        src="./images/configuration/es.configuration_01.png"
        max-width="300"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Activando el check de “Aviso sonoro al recibir un pedido” el sistema nos
        avisa con un sonido siempre que recibimos un nuevo pedido, ya sea
        pedido, solicitud de cuenta o llamada al camarero.
      </p>

      <v-img
        src="./images/configuration/es.configuration_02.png"
        max-width="300"
      ></v-img>

      <p class="pt-5">
        Activando el check de “Aviso sonoro cuando un cliente escanea un código”
        el sistema nos avisa con un sonido siempre que un nuevo cliente escanea
        algún código QR de cualquier mesa.
      </p>

      <v-img
        src="./images/configuration/es.configuration_03.png"
        max-width="450"
      ></v-img>

      <p class="pt-5">
        El tiempo de sesión de clientes no es más que un “seguro” para
        deshabilitar al cliente automáticamente pasado un tiempo en segundos.
        Por ejemplo, si queremos que se desactive después de 1 hora aquí tenemos
        que introducir el valor 3600 (60 x 60). Nosotros recomendamos un tiempo
        de 3 horas para los clientes que vienen a comer - por tanto 10800.
      </p>

      <p>
        Una vez pasados estos segundos desde que el cliente ha escaneado el
        código se deshabilitará automáticamente el acceso y debe volver a
        escanear el código QR de mesa.
      </p>

      <v-img
        src="./images/configuration/es.configuration_04.png"
        max-width="550"
      ></v-img>

      <p class="pt-5">
        Idiomas disponibles. Aquí podemos indicar al sistema cuales son los
        idiomas de los que vamos a disponer en nuestro restaurante. De momento
        solo tenemos disponible español e inglés. Si solo queremos que nuestra
        carta este en español seleccionamos solo el idioma español.
      </p>

      <p>
        De esta configuración depende tanto que se le muestra al cliente como
        todas las opciones de texto (campo para nombre o descripciones de
        productos) en la parte de administración.
      </p>

      <v-img
        src="./images/configuration/es.configuration_05.png"
        max-width="550"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        El idioma por defecto se utiliza para cuando no tenemos disponibles
        traducciones para el idioma seleccionado por el cliente, lo mejor es que
        este valor se quede con español ya que siempre vamos a tener el nombre o
        la descripción en español.
      </p>

      <v-img
        src="./images/configuration/es.configuration_06.png"
        max-width="550"
        class="mx-auto"
      ></v-img>

      <p class="pt-5">
        Por último en la parte inferior tenemos el botón guardar para persistir
        los cambios realizados. Al hacer clic sobre el botón nos guarda las
        preferencias y muestra el mensaje de confirmación “La configuración se
        ha actualizado correctamente.”
      </p>

      <v-img
        src="./images/configuration/es.configuration_07.png"
        class="mx-auto"
      ></v-img>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {};
</script>

<style></style>
